.c-article-reward {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.2rem;
  padding-bottom: 0.4rem;
}

.c-article-reward .reward-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1.6rem;
  height: 0.6rem;
  border-radius: 0.06rem;
  background-color: #f76c69;
  color: #fff;
  font-size: 0.28rem;
}

.c-article-reward .reward-info {
  font-size: 0.24rem;
  color: #333;
  margin: 0.15rem 0;
}

.c-article-reward .reward-info > span {
  color: #f76c69;
}

.c-article-reward .reward-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-article-reward .avatar-list {
  position: relative;
}

.c-article-reward .avatar-list::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  background: transparent;
  z-index: 7;
}