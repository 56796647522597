.m-art-reward-item {
  padding: 0.3rem 0.2rem;
  color: #999;
}

.m-art-reward-item time {
  font-size: 0.24rem;
  color: #ccc;
}

.m-art-reward-item h2 {
  font-size: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.m-art-reward-item h2 b {
  color: #000;
  margin-right: 0.1rem;
}