.c-article-like {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  font-size: 0.24rem;
  color: #ccc;
}

.c-article-like .like-list {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #59b6d7;
}

.c-article-like .like-list .avatar-list {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.c-article-like .like-list .avatar-list .liker-avatar {
  width: 0.5rem;
  height: 0.5rem;
}

.c-article-like .like-count {
  margin-left: 0.5em;
  color: #59b6d7;
}

.c-article-like .article-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  line-height: 0.3rem;
}