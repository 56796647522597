.c-article-card {
  position: relative;
  height: 100%;
}

.c-article-card .m-art-card {
  min-height: 100%;
}

.c-article-card .m-art-card > header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
}

.c-article-card .m-art-card > main {
  margin-top: 0.9rem;
  margin-bottom: 1.02rem;
}

.c-article-card .m-art-card > footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  top: initial;
  height: 0.95rem;
  font-size: 0.24rem;
  background-color: #fff;
  border-top: 1px solid #ededed;
}

.c-article-card .m-art-card > footer a {
  color: #b3b3b3;
}

.c-article-card .m-art-card > footer a.primary {
  color: #59b6d7;
}

.c-article-card .m-art-card > footer span {
  margin-top: 0.04rem;
}

.c-article-card .m-art-card > footer .liked {
  color: #f76c69;
}